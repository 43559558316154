import React from 'react'
import {FluidContainer} from '../../components'

import './ourTeam.css'

export default function() {
    return (
        <FluidContainer>
            <h1 className="text-center">Meet our Team</h1>
            <hr />
            <div id="Karen" className="row teamMember">
                <div className="col-md-3">
                    <img alt="Karen" src="/assets/images/about-us/our-team/KarenSquared.jpg" className="profilePic" />
                </div>
                <div className="col-md-9">
                    <h3>Karen Feuerborn</h3>
                    <h5>Owner &amp; C.E.O.</h5>
                    <p>Karen was born and raised in Sandy Springs, Georgia.&ensp;She obtained a Minor in Ibero-American Studies from CIS Madrid Spain, a BS in Computer Science and BS in Mathematics from SMU graduating a Dean’s Honor Scholar, and an MBA from SMU graduating a Beta Gamma Sigma Honor Scholar.&ensp;Karen’s career consisted of 26 years in IT development and management including executive roles for JCPenney, a Fortune 50 Corporation.&ensp;Karen took early retirement to spend time with her three children during middle and high-school.&ensp;She has a passion for nature and wildlife!&ensp;After their youngest went off to college, she and her husband Chris felt the calling to become ranchers.&ensp;In 2017, Karen and Chris were blessed to have the longstanding Foster-Russell ranch legacy transitioned to their family.&ensp;The family has since enjoyed learning the ropes of farming and ranching, and they are now expanding the Split Oak Ranch business.</p>
                </div>
            </div>
            <hr className="mobileSeparator" />
            <div id="Chris" className="row teamMember">
                <div className="col-md-3">
                    <img alt="Chris" src="/assets/images/about-us/our-team/Chris3Squared.jpg" className="profilePic" />
                </div>
                <div className="col-md-9">
                    <h3>Chris Wharton</h3>
                    <h5>President &amp; C.O.O.</h5>
                    <p>Chris was born in Houston, Texas.&ensp;He grew up a “military brat” of an Army Green Beret and lived in many different places throughout the US and Europe.&ensp;He followed the footsteps of his heritage, becoming the 5th generation in his family to serve in the US Military.&ensp;Chris is now a US Air Force veteran, and he continues to have a passion for all things military and all things airworthy.&ensp;He is actively involved in several endeavors to support US military veterans, wounded warriors, and survivors of soldiers lost in theater.&ensp;Chris has been a civilian pilot for over 21 years now, and currently flies 737s for American Airlines.</p>
                </div>
            </div>
            <hr className="mobileSeparator" />
            <div id="Lenny" className="row teamMember">
                <div className="col-md-3">
                    <img alt="Lenny" src="/assets/images/about-us/our-team/Lenny3Squared.jpg" className="profilePic" />
                </div>
                <div className="col-md-9">
                    <h3>Lenny Walker</h3>
                    <h5>Ranch Manager</h5>
                    <p>Lenny brings a wealth of experience in business management, livestock work, and professional welding.</p>
                    <ul>
                        <li>Owner of WJ Welding in Marietta, OK (6 years)</li>
                        <li>Manager of Orientation Dorm at Texas Youth Commission, a youth correctional facility in Gainesville, TX (9 years)</li>
                        <li>Top welder and instructor at Texas Youth Commission. (9 years)</li>
                        <li>Electrical Foreman for KTEX in Houston, TX (4 years)</li>
                        <li>Managed Ardmore Livestock Sale Barn in Ardmore, OK (5 years)</li>
                    </ul>
                    <p>Lenny has a wife and two children and lives in Marietta OK.&ensp;He enjoys spending time and guiding hunting and fishing trips.</p>
                </div>
            </div>
            {/* <hr className="mobileSeparator" />
            <div id="Dale" className="row teamMember">
                <div className="col-md-3">
                    <img alt="Dale" src="/assets/images/about-us/our-team/Dale2Squared.jpg" className="profilePic" />
                </div>
                <div className="col-md-9">
                    <h3>Dale Faulkenberry</h3>
                    <h5>Assistant Ranch Manager</h5>
                    <p>We like to say that Dale was born with Boots and Spurs <span role="img" aria-label="smiley face emoji">&#128522;</span>  He has been in farming and ranching his entire life.&ensp;His background includes:</p>
                    <ul>
                        <li>Competition Rodeo in Bareback, Bull Riding, Calf Roping and Team Roping (25 years)</li>
                        <li>Lead Welder CM Trailer (6 years)</li>
                        <li>Service Department, Michelin Ardmore Manufacturing (18 years)</li>
                        <li>Boot and Saddle Repair (15 years)</li>
                    </ul>
                    <p>Dale has a wife and three children and lives in Marietta, OK.&ensp;With his spare time, he enjoys team roping, coon hunting and chasing his grand kids!  We think he was also born with a lasso in his hand <span role="img" aria-label="winking face emoji">&#128521;</span></p>
                </div>
            </div>
            <hr className="mobileSeparator" />
            <div id="Cierra" className="row teamMember">
                <div className="col-md-3">
                    <img alt="Cierra" src="/assets/images/about-us/our-team/CierraSquared.jpg" className="profilePic" />
                </div>
                <div className="col-md-9">
                    <h3>Cierra Faulkenberry</h3>
                    <h5>Horse Trainer</h5>
                    <p>Cierra attends North Central Texas College in Gainesville tx, and plans on going to West Texas A&amp;M in Canyon, Tx in Fall 2021 for Animal Science Production/Industry option.&ensp;She rodeoed in college, and has experience in open rodeo and association rodeo, and all breakaway roping.</p>
                </div>
            </div> */}
            <hr className="mobileSeparator" />
            <div id="Tim" className="row teamMember">
                <div className="col-md-3">
                    <img alt="Tim" src="/assets/images/about-us/our-team/TimSquared.jpg" className="profilePic" />
                </div>
                <div className="col-md-9">
                    <h3>Tim Stokes</h3>
                    <h5>Agricultural Specalist</h5>
                    <p>Tim brings a strong background in agriculture and livestock.&ensp;He retired from the Noble Research Institute a preeminent agricultural research institute.&ensp;Nobel is a trusted source of transformational knowledge and products, education and technology for the advancement of agriculture and land stewardship.</p>
                    <ul>
                        <li>Farm and Ranch Technician for Noble Research Institute in Ardmore, OK. (20 years) where he managed 250 head of commercial cattle for Noble.</li>
                        <li>Equipment Operator McGill Backhoe (5 years)</li>
                    </ul>
                    <p>Tim has two children and lives in Marietta, OK.&ensp;He enjoys spending time with his grandchildren and assisting his son’s Chuck Wagon Racing Team – “Diamond B” who won the Rookie Race in 2019.</p>
                </div>
            </div>
            <hr className="mobileSeparator" />
            <div id="Thomas" className="row teamMember">
                <div className="col-md-3">
                    <img alt="Thomas" src="/assets/images/about-us/our-team/Thomas2Squared.jpg" className="profilePic" />
                </div>
                <div className="col-md-9">
                    <h3>Thomas Feuerborn</h3>
                    <h5>Head of IT,&ensp;US Marine</h5>
                    <p>Thomas is Karen’s firstborn son, and like his mother he has always had an interest in technology.</p>
                    <p>Thomas studied Web Development and Computer Science in college, and he brings a great deal of knowledge to set up and run the day to day technologies at the ranch (including this website <span role="img" aria-label="smiley face emoji">&#128522;</span>).&ensp; He is currently in training with the United States Marine Corps.&ensp;In his spare time, Thomas is an amateur music producer. </p>
                </div>
            </div>
            {/* <hr className="mobileSeparator" />
            <div id="Christopher" className="row teamMember">
                <div className="col-md-3">
                    <img alt="Christopher" src="/assets/images/about-us/our-team/ChristopherSquared.jpg" className="profilePic" />
                </div>
                <div className="col-md-9">
                    <h3>Christopher Feuerborn</h3>
                    <h5>TITLE GOES HERE</h5>
                    <p><pre><code>WORK IN PROGRESS</code></pre></p>
                </div>
            </div>
            <hr className="mobileSeparator" />
            <div id="Kathleen" className="row teamMember">
                <div className="col-md-3">
                    <img alt="Kathleen" src="/assets/images/about-us/our-team/KathleenSquared.jpg" className="profilePic" />
                </div>
                <div className="col-md-9">
                    <h3>Kathleen Feuerborn</h3>
                    <h5>TITLE GOES HERE</h5>
                    <p><pre><code>WORK IN PROGRESS</code></pre></p>
                </div>
            </div> */}
            <hr className="mobileSeparator" />
            <div id="Devin" className="row teamMember">
                <div className="col-md-3">
                    <img alt="Devin" src="/assets/images/about-us/our-team/DevinSquared.jpg" className="profilePic" />
                </div>
                <div className="col-md-9">
                    <h3>Devin Miller</h3>
                    <h5>Ranch Hand,&ensp;US Marine</h5>
                    <p>Devin brings a lot of energy to our team.&ensp;He graduated Gainesville High School Spring of 2019 where he was a two time Regional Power Lifter and played Varsity Football.&ensp;He is also in training with the United States Marine Corps, and is proudly serving in the US Military.&ensp;</p>
                </div>
            </div>
            <hr className="mobileSeparator" />
            <div id="Sam" className="row teamMember">
                <div className="col-md-3">
                    <img alt="Sam" src="/assets/images/about-us/our-team/Sam2Squared.jpg" className="profilePic" />
                </div>
                <div className="col-md-9">
                    <h3>Sam Lynch</h3>
                    <h5>"Management"</h5>
                    <p>Sam has been a close family friend for many years and spends much of his free time here at Split Oak, helping out with Hay Operations, Land Management and various other tasks — he jumps at any opportunity to operate the farm equipment or care for the animals! In 2019, he graduated as a John V. Roach Honors College Laureate at TCU, with a Bachelor of Science. When he is not visiting us at Split Oak, he is a Project Manager in Fort Worth with integrated design/build firm, The Beck Group.</p>
                </div>
            </div>
            <hr />
            <hr className="mobileSeparator" />
            <h3 className="text-center">Our Friends, Colleagues, and Business Associates</h3>
            <hr />
            <hr className="mobileSeparator" />
            <div id="Doc" className="row teamMember">
                <div className="col-md-3">
                    <img alt="Doc" src="/assets/images/about-us/our-team/DocSquared.jpg" className="profilePic" />
                </div>
                <div className="col-md-9">
                    <h3>Doug Nightengale, DVM</h3>
                    <h5>Large Animal Veterinarian</h5>
                    <p>With over 30 years experience as a large animal veterinarian, Doctor Nightengale helps us care for our livestock, both cattle and horses. He is a critical resource for us in many ways, and most especially in our precious maternity ward. <span role="img" aria-label="smiley face emoji">&#128522;</span></p>
                </div>
            </div>
            <hr className="mobileSeparator" />
            <div id="Bluebonnet" className="row teamMember">
                <div className="col-md-3">
                    <img alt="Bluebonnet Feeds" src="/assets/images/about-us/our-team/BluebonnetSquared.png" className="profilePic blackOutline" />
                </div>
                <div className="col-md-9">
                    <h3>Bluebonnet Feeds</h3>
                    <h5>Premium Feed Manufacturer</h5>
                    <p>Bluebonnet works with us to assure our feed plan best meets the varying nutritional needs of our livestock.</p>
                </div>
            </div>
            <hr className="mobileSeparator" />
            <div id="Anne" className="row teamMember">
                <div className="col-md-3">
                    <img alt="Anne" src="/assets/images/about-us/our-team/AnneSquared.jpg" className="profilePic" />
                </div>
                <div className="col-md-9">
                    <h3>Anne Hutchinson</h3>
                    <h5>Cutting Horse Breeder &amp; Trainer</h5>
                    <p>Anne brings over 40 years experience in horsemanship and cutting horses as a professional trainer and an NCHA judge. She has been a trusted advisor as we've learned the ropes and developed our business plans.</p>
                </div>
            </div>
        </FluidContainer>
    )
}