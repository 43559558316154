import React from 'react'
import { FluidContainer } from '../../components'

import './hay.css'

export default function() {
    return (
        <FluidContainer>
            <h1 className="text-center">Hay</h1>
            <hr />
            <p>Hay is Split Oak Ranch's principal farm product. We harvest 150-250 acres of Midland 99 Bermuda grass 2-3 times per season. We produce large round bales (1250Lbs/Bale) as well as small 2-string square bales (75Lbs/Bale). We produce both horse and cow grade hays. We typically have hay available for sale year round. Market Prices.</p>
            <div className="row">
                <div id="hayTractors" className="col-xl-12 border1">
                </div>
            </div>

            <div className="row">
                <div id="hayBlur" className="offset-xl-3 col-xl-6 border1"></div>
            </div>
            
            {/* <div id="tractorPic" className="row">
                <div className="col-xl-12" style={{width: '100%', height: '100%'}}>
                    <div id="hayTractors" className="border1 hayPic" style={{width: '100%', maxHeight: '100%'}}>
                        <img alt="" className="hiddenImage" src="/assets/images/farming/hay/HayTractors.jpg"></img> 
                    </div>
                </div>
            </div>
            <div id="hayPics" className="row">
                <div className="col-xl-6" style={{width: '100%', height: '100%'}}>
                    <div id="hayBlur" className="border1 hayPic" style={{width: '100%', height: '100%'}}>
                        <img alt="" className="hiddenImage" src="/assets/images/farming/hay/HayBlur.jpg"></img> 
                    </div>
                </div>
            </div> */}
        </FluidContainer>
    )
}
