import React, { Component } from 'react';
import {FluidContainer} from '../../components';
import {Carousel} from 'react-bootstrap';

import './specialEvents.css';

export default class SpecialEvents extends Component {
    render() {
        return (
            <FluidContainer>
                <h1 className="text-center">Special Events</h1>
                <hr />
                <p>Being a family run ranch &amp; farm, we are family focused! We welcome folks to consider our facilities for a variety of events:</p>
                <ul>
                    <li>
                        Small Group Meetings &amp; Team Building Activities
                    </li>
                    <li>
                        Farm &amp; Ranch Tours for Students &amp; Kids
                    </li>
                    <li>
                        Riding Arena for training &amp; special events
                    </li>
                    <li>
                        Barn Weddings or other special events (can seat up to 220 in our barn for service and/or dinner)
                    </li>
                </ul>
                <div className="card bg-dark">
                    <Carousel id="eventsCarousel" pauseOnHover={false} indicators={false}>
                        <Carousel.Item id='BabyCalf'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/bnb/special-events/BabyCalf.jpg" alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='MommaAndCalf'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/bnb/special-events/MommaAndCalf.jpg" alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='PettingCow'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/bnb/special-events/PettingCow.jpg" alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='PicturesOfCalf'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/bnb/special-events/PicturesOfCalf.jpg" alt=""/>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </FluidContainer>
        )
    }
}
