import React, {Component} from 'react'
import { FluidContainer } from '../../components';

import {Carousel} from 'react-bootstrap'

import './horses.css';

const imageList = [
    "ArcherAndWendy",
    "BabyPhantom",
    "BabyWendy",
    "Barbie",
    "ChrisAndAbbie",
    "ChristopherAndHorses",
    "Clydes",
    "ClydesInStalls",
    "Dottie",
    "Dusty",
    "FeedingHorses",
    "Gracie",
    "GracieAndJaxie",
    "GrazingClydes",
    "GrazingHorses",
    "KarenAbbieAndRain",
    "Red",
    "RedAndDusty",
    "RunningHorses",
    "TheGirls",
    "ThunderFunny",
    "ThunderVioletAndTractor",
    "ViewFromAStall"
]

export default class Horses extends Component {
    render(){  
        return (
            <FluidContainer>
                <h1 className="text-center">Horses</h1>
                <hr />
                <p>Split Oak Ranch breeds several varieties of registered horses</p>
                <ul>
                    <li>Drums - International Drum Horse Association</li>
                    <li>Quarter Horses - American Quarter Horse Association</li>
                    <li>Clydesdales - Clydesdales Breeders of the U.S.A.</li>
                    <li>Paint Horses - Amerian Paint Horse Association</li>
                </ul>
                <p>Feel free to follow us on our facebook page <a target="_blank" rel="noopener noreferrer"  href="https://www.facebook.com/Split-Oak-Ranch-108893650660175">here</a> where you can learn more about our horses, breeding, and fun!</p>
                <div className="card bg-dark">
                    <Carousel id="horseCarousel" pauseOnHover={false} indicators={false}>
                    {imageList.map(filename => (
                        <Carousel.Item key={filename} id={filename}>
                            <img
                                    alt={filename}
                                style={{
                                    backgroundImage: `url("/assets/images/giving-back/land-management/${filename}.jpg")`,
                                    backgroundSize: "contain"
                                }}
                                className="d-block mx-auto hiddenImage"
                                src={`/assets/images/giving-back/land-management/${filename}.jpg`}
                            />
                        </Carousel.Item>
                    ))}
                    </Carousel>
                </div>
            </FluidContainer>
        )
    }
}