import React from 'react';
import {FluidContainer} from '../../components'

import './contactUs.css'
import '../home.css'

export default function() {
    return (
        <FluidContainer>
            <h1 className="text-center">Contact Us</h1>
            <hr />
            <div className="border1" id="sunnyRanch"></div>
            <div style={{paddingTop: 25, paddingBottom: 25}} className="text-center row">
                <div className="col-xl-4 border1" id="sunsetTree">
                    <img className="hiddenImage" src='/assets/images/about-us/contact-us/Sunset%20Tree.jpg' alt='Beautiful Sunsets!'></img>
                </div>
                <div className="col-xl-4">     
                    <h3>Split Oak Ranch</h3>
                    <p style={{paddingTop: 10}}>12494 Dover Rd</p>
                    <p>Marietta, OK, 73448</p>
                    <hr />
                    <p>For pricing and availability inquiries, please email us at:</p>
                    <p><a href="mailto: karen@splitoak-ranch.com">karen@splitoak-ranch.com</a></p>
                    <p>—or—</p>
                    <p>Like us on our <a href="https://www.facebook.com/Split-Oak-Ranch-108893650660175/" target="_blank" rel="noopener noreferrer">Facebook Page</a> for all things Split Oak Ranch!</p>
                </div>
                <div id="daytimeBarn" className="col-xl-4 border1">
                    <img className="hiddenImage" src="/assets/images/about-us/contact-us/SunsetBarn.jpg" alt="Our Beautiful Barn"></img>
                </div>
            </div>
            <iframe
                title='Map'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3311.9742607252456!2d-97.16739938444711!3d33.89031673369267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864d1f8d59093c7b%3A0x674cde71f4a92308!2sSplit%20Oak%20Ranch!5e0!3m2!1sen!2sus!4v1592146769392!5m2!1sen!2sus"
                width="100%"
                height="375px"
                frameBorder="0"
                style={{border:0}}
                allowFullScreen=""
            >
            </iframe>
        </FluidContainer>
    );
}