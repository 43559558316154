import LandManagement from './landManagement'

import Ponds from './ponds'
import RotationalCrops from './rotationalCrops'
import LandRestoration from './landRestoration'
import NaturalHabitat from './naturalHabitat'
import ProtectSoil from './protectSoil'

export default LandManagement
export {Ponds, RotationalCrops, LandRestoration, NaturalHabitat, ProtectSoil}
