import React from 'react';

import './landRestoration.css'

function LandContent() {
    return (
        <div className="col-xl-9">
            <h1 className="text-center">Land Restoration</h1>
            <hr />
            <p style={{textAlign: 'justify'}}>We strive to take excellent care of our land. For example, the ranch completed a project to clean up an old abandoned oil company drill site on the property, and restore the land to its natural setting.</p>
        </div>
    )
}

function LandPicture () {
    return (
        <div className="col-xl-3">
            <div id="landRestoration" className="border1">
                <img id="landRestorationPic" className="hiddenImage" alt="" src="/assets/images/giving-back/land-management/RollingGreenHills.jpg"></img>
            </div>
        </div>
    );
}

export default function() {
    return (
        window.innerWidth > 1200 ? (
            <div className="row">
                <LandPicture />
                <LandContent />
            </div>
        ) : (
            <div className="row">
                <LandContent />
                <LandPicture />
            </div>
        )
    );
}