import React from 'react'

import './rotationalCrops.css'

export default function() {
    return (
        <div className="row">
            <div className="col-xl-9">
                <h1 className="text-center">Rotate Crops &amp; Cattle Grazing</h1>
                <hr />
                <p style={{textAlign: 'justify'}}>We carefully monitor use of our land and protect the environment. We rotate livestock so as not to over-graze our fields, and rotate crops to ensure the land is replenished and kept healthy and functional.</p>
            </div>

            <div className="col-xl-3">
                <div id="crops" className="border1">
                    <img id="cropPic" className="hiddenImage" alt="" src="/assets/images/giving-back/land-management/Hayfield2.jpg"></img>
                </div>
            </div>
        </div>
    )
}
