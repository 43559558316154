import React from 'react';

import './protectSoil.css'

function SoilContent () {
    return (
        <div className="col-xl-9">
            <h1 className="text-center">Protect Soil</h1>
            <hr />
            <p style={{textAlign: 'justify'}}>We maintain land terracing and drainage systems to minimize erosion and loss of some of the most fertile farming soil in North America.</p>
        </div>
    );
}

function SoilImage () {
    return (
        <div className="col-xl-3">
            <div id="soil" className="border1">
                <img id="soilPic" className="hiddenImage" alt="" src="/assets/images/giving-back/land-management/Wildflowers.jpg"></img>
            </div>
        </div>
    );
}

export default function() {
    return (
        window.innerWidth > 1200 ? (
            <div className="row">
                <SoilImage />
                <SoilContent />
            </div>
        ) : (
            <div className="row">
                <SoilContent />
                <SoilImage />
            </div>
        )
    )
}