import React from 'react'
import { FluidContainer } from '../../components'

import './equestrian.css'

export default function() {
    return (
        <FluidContainer>
            
            <div style={{width: "100%", textAlign: "center"}}>
                <img id="EquestrianExterior" alt="" className="border1" src="/assets/images/equestrian/NightBarn.jpg"></img>
            </div>
            <h1 className="text-center">Equestrian</h1>
            <hr />
            <p>Split Oak Ranch just completed construction of our state of the art Equestrian Center with an oversized Post and Beam Barn, plush living quarters, and nearing completion of our full sized rodeo arena.</p>
            
            <div className="row">
                <div className="offset-xl-2 col-xl-8">
                    <img id="stalls" alt="" className="border1" src="/assets/images/equestrian/horses/ClydesInStalls.jpg"></img>
                </div>
            </div>
        </FluidContainer>
    )
}
