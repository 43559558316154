import React from 'react'

export default function() {
    return (
        <div>
            <h1 className="text-center">Giving Back &amp; Stewardship</h1>
            <hr />
        </div>
    )
}
