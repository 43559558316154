import React from 'react'

export default function () {
    return (
        <div>
            <h1 className="text-center">Patriot Roundup</h1>

            <div style={{
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'space-around'
            }}>
                <div style={{
                    flex: '0 1 auto',
                    maxWidth: '40%'
                }}>
                    <img
                        alt="Patriot Roundup Gala Info"
                        style={{
                            maxWidth: '100%',
                            height: 'auto',
                            maxHeight: '75vh'
                        }}
                        src='/assets/images/special-events/patriot-roundup/GalaInfo.jpg'
                    ></img>
                </div>
                <div style={{
                    flex: '0 1 auto',
                    maxWidth: '40%'
                }}>
                    <img
                        alt="Sponsorship Opportunities"
                        style={{
                            maxWidth: '100%',
                            height: 'auto',
                            maxHeight: '75vh'
                        }}
                        src='/assets/images/special-events/patriot-roundup/SponsorshipOpportunities.jpg'
                    ></img>
                </div>
            </div>
        </div>
    )
}