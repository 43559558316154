import React from 'react'
import { FluidContainer } from '../../components'

import './supportingMilitary.css'

export default function(){
    return(
        <FluidContainer>
            <h1 className="text-center">Supporting our Military</h1>
            <hr/>
            <p>At Split Oak Ranch, we believe in giving back to our communnity and country. Along those lines, we support the US Military and our local communnity.</p>
            <ul>
                <li>Split Oak Ranch hosts an Annual Wounded Warrior Hunt</li>
                <li>We also host visiting veterans</li>
                <li>We actively participate in various fund raising events for veterans and surviving families</li>
                <li>2020 will host the first Split Oak Ranch Rodeo Fund Raiser for our Military</li>
            </ul>
            <div className="text-center">
                <img alt="Deer Hunt" src="/assets/images/giving-back/supporting-military/DeerHuntCropped.jpg" id="deerHunt" className="border1 text-center" />
            </div>
        </FluidContainer>
    )
}