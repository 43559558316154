import React, { Component } from 'react'
import { LandCarousel, FluidContainer } from '../components'
import { Link } from 'react-router-dom'

import './giving-back/land-mgmt/landManagement.css'
import './home.css'

export default class Home extends Component {

    render () {
        return (
            <FluidContainer>
                <h1 className="text-center">Split Oak Ranch</h1>
                <h4 className="text-center slogan"><em>"Where Roots Run Deep"</em></h4>
                {/* <br/> */}
                <p className='text-center' >12492 Dover Rd</p>
                <p className='text-center' >Marietta OK, 73448</p>
                <br/>
                <h3 className='text-center'>For Info:</h3>
                <p className='text-center'>Karen: (214) 995-7848</p>
                <p className='text-center'>Chris: (817) 707-1132</p>
                <hr/>
                <div className="border1" id="sunnyRanch"></div>
                <div style={{paddingTop: 25}} className="text-center row">
                    <div className="col-xl-3 border1" id="sunsetTree">
                        <img className="hiddenImage" src='/assets/images/about-us/contact-us/Sunset%20Tree.jpg' alt='Beautiful Sunsets!'></img>
                    </div>
                    <div className="col-xl-6">     
                        <p style={{textAlign: 'justify'}}>Split Oak Ranch is a family owned and operated 300+ acre ranch located on the hills above the Red River in Marietta, Oklahoma. We primarily produce beef cattle, breed horses, and harvest hay.</p>
                        <p style={{textAlign: 'justify'}}>Split Oak Ranch also has a one-of-a-kind <Link to="/b+b-and-special-events">Horse Bed &amp; Breakfast / Training Facility</Link>, and can host traveling horse trainers, owners, and horses. Our Barn and B&amp;B are available for weddings or other special events on a select basis.</p>
                    </div>
                    <div id="daytimeBarn" className="col-xl-3 border1">
                        <img className="hiddenImage" src="/assets/images/about-us/contact-us/SunsetBarn.jpg" alt="Our Beautiful Barn"></img>
                    </div>
                    <div className="card bg-dark" style={{marginTop: 20, width: '100%'}}>
                        
                    <LandCarousel mainPage />
                    </div>
                </div>
                
            </FluidContainer>
        )
    }
    
}