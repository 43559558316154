import React from 'react'

import { FluidContainer } from '../../components'

export default function() {
    return (
        <FluidContainer>
            <h1 className="text-center">Hemp</h1>
            <hr />
            <p>Hemp is an emerging crop and is new for this area as federal and state legislation has recently enabled it. Hemp is new crop for Split Oak Ranch with our first production planned for Summer 2020.</p>
            {/* eslint-disable-next-line */}
            <p>Hemp is used to produce CBD Oil, a non-THC product, that is growing in popularity in the health industry as an effective natural treatment for arthritis, sleep, anxiety, and other health problems. <a href="#">Link</a></p>
            <p>Split Oak Ranch <u>will not sell hemp or hemp products directly to the public</u>. Rather, we will sell high quality, FDA tested, harvested crops to well-established manufacturers of Hemp Oil Products like CBD Oil.</p>
        </FluidContainer>
    )
}
