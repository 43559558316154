import React from 'react'
import { FluidContainer } from '../../components'

export default function(){
    return(
        <FluidContainer>
            <h1 className="text-center">Community Service</h1>
            <hr/>
            <p>At Split Oak Ranch, we believe in supporting and building our community through various channels</p>
            <ul>
                <li>We pitch in to keep our streets clean!</li>
                <li>We're available 24/7 to help neighbors  in need</li>
                <li>We give financial support to our local Volunteer Fire Departments</li>
                <li>We Help preserve local history. For example, a Craftsman House was moved to the Farm/Ranch before Lake Texoma was built, then later the house was moved to downtown Marietta and given to the local Mail Carrier's family.</li>
            </ul>
            <div className="text-center">
                <img className="border1" src="/assets/images/giving-back/community-service/Tinkerbell.jpg" alt="" id="Tinkerbell" style={{height: "58vh", width: 'auto'}}/>
            </div>
        </FluidContainer>
    )
}