import React, { Component } from 'react';
import {FluidContainer} from '../../components';
import {Carousel} from 'react-bootstrap';

import './horseBNB.css';

export default class HorseBNB extends Component {
    render() {
        return (
            <FluidContainer>
                <h1 className="text-center">Horse Bed &amp; Breakfast</h1>
                <hr />
                <p>We have a one bedroom studio apartment located above the barn that is available for guests. Can accommodate 2-4 guests.</p>
                <p>We have 8 stalls available for visiting horses, with access to our training facilities and riding grounds/trails. We can provide live cattle for cutting or roping practice on a limited basis.</p>
                <p>This is a great setup for horse trainers and owners preparing for a riding competition in the area, from Oklahoma City - 2 hours away, to Ft. Worth TX - 80 minutes away, with Ardmore OK right in the middle only 20 minutes away! It is also a great setup for folks from the metroplex who wish to ride their own horses in the country for a change of scenery.</p>
                <p>Highly Experienced Equine Veterinarian on call.</p>
                <div className="card bg-dark">
                    <Carousel id="bnbCarousel" pauseOnHover={false} indicators={false}>
                        <Carousel.Item id='Alleyway'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/bnb/Alleyway.jpg"alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='Apartment'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/bnb/Apartment.jpg"alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='Bed'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/bnb/Bed.jpg"alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='BreakfastTable'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/bnb/BreakfastTable.jpg"alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='Capacity'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/bnb/Capacity.jpg"alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='Entryway'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/bnb/Entryway.jpg"alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='HorseInStall'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/bnb/HorseInStall.jpg"alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='Kitchen'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/bnb/Kitchen.jpg"alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='KitchenAndLivingRoom'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/bnb/KitchenAndLivingRoom.jpg"alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='LivingRoom'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/bnb/LivingRoom.jpg"alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='NightBarn'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/bnb/NightBarn.jpg"alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='Shower'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/bnb/Shower.jpg"alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='Stalls'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/bnb/Stalls.jpg"alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='Stalls2'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/bnb/Stalls2.jpg"alt=""/>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </FluidContainer>
        )
    }
}
