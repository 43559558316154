import React from 'react'
import {FluidContainer} from '../../components'

export default function() {
    return (
        <FluidContainer>
            <h1 className="text-center">Philosophy</h1>
            <hr />
            <p>Our philosophy is to protect and leverage the natural resources God gave us to build a better tomorrow for our livestock, land, and community.</p>
            <br />
            <p>Like the generations before us, we feel blessed to have the opportunity to farm and ranch in Southern Oklahoma, and we take stewardship of our land and livestock very seriously. We have a wonderful team of hands, who not only operate Split Oak on a daily basis, but also take pride in helping us develop future businesses and share the same passion we have for our livestock, land, and community.</p>
            <div className="text-center">
                <img className="border1" src="/assets/images/giving-back/land-management/Pasture5.jpg" alt="" id="Pasture5" style={{height: "58vh", width: 'auto'}}/>
            </div>
        </FluidContainer>
    )
}
