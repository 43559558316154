import React from 'react'

import './ponds.css'

export default function() {
    return (
        <div className="row">
            <div className='col-xl-9'>
                <h1 style={{width: '100%'}} className="text-center">Ponds &amp; Creeks</h1>
                <hr />
                <p style={{textAlign: 'justify'}}>We work with the US Government to establish additional spring fed ponds to protect a critical natural resource: fresh water! There are 7 established ponds &amp; miles of limestone bedded creeks that support an abundance of wildlife — including our livestock — with natural water sources. They are also critical to the proliferation of the local honey bees.</p>
            </div>
            <div className="col-xl-3">
                <div id="ponds" className="border1">
                    <img id="pondPic" className="hiddenImage" alt="" src="/assets/images/giving-back/land-management/Creek3.jpg"></img>
                </div>
            </div>
        </div>
    )
}
