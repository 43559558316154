import React from 'react'
import {FluidContainer} from '../../components'

import './ridingTrainingFacility.css'
import { Link } from 'react-router-dom'

export default function() {
    return (
        <FluidContainer>
            <h1 className="text-center">Riding / Training Facility</h1>
            <hr />
            <div className="row">
                <div className="col-xl-2">
                    <img id="viewFromStall" alt="" className="border1" src="/assets/images/equestrian/horses/ViewFromAStall.jpg"></img>
                </div>
                <div className="col-xl-6">
                    <p>Our facilities are available on a limited basis for riding, special events, and <Link to='/b+b-and-special-events'>bed &amp; breakfast</Link> guests in which guests may bring their own horses to train in our arena or ride our timberland trails. We can host up to 8 guest horses. Please inquire for details by emailing us at <a href="mailto: info@splitoak-ranch.com">info@splitoak-ranch.com</a></p>
                    <p>Our riding arena is a full sized rodeo arena at 250ft by 160ft, with stock holding pens, return alleys, roping chutes, and can be configured for any type of rodeo event: team roping, barrels, cutting, reining, and even rough stock events!</p>
                </div>
                <div className="col-xl-4">
                    <img id="exterior" alt="" className="border1" src="/assets/images/about-us/contact-us/SunsetBarn.jpg"></img>
                </div>
            </div>
        </FluidContainer>
    )
}
