import React from 'react';
import { Link } from 'react-router-dom';
import {FluidContainer} from '../../components'

export default function() {
    return (
        <FluidContainer>
            <h1 className="text-center">Who We Are &amp; Our History</h1>
            <hr/>
            <p>Split Oak Ranch is a family owned and operated 300+ acre ranch located on the hills above the Red River in Marietta, Oklahoma. We primarily produce beef cattle, breed horses, and harvest hay.</p>
            <p>Split Oak Ranch also has a one-of-a-kind <Link to="/b+b-and-special-events">Horse Bed &amp; Breakfast / Training Facility</Link>, and can host a variety of special events including barn weddings on a select basis.</p>
        </FluidContainer>
    );
}