import GivingBack from './givingBack'

import LandManagement from './land-mgmt'
import Philosophy from './philosophy'
import CommunityService from './communityService'
import SupportingMilitary from './supportingMilitary'

export default GivingBack
export {
    LandManagement,
    Philosophy,
    SupportingMilitary,
    CommunityService
}