import React from 'react'
import { FluidContainer } from '../../components'

import './honey.css'

export default function() {
    return (
        <FluidContainer>
            <h1 className="text-center">Honey</h1>
            <hr />
            <p>Split Oak Ranch established our first honey bee colonies in Spring of 2019 and plan to harvest our first batch of local honey Summer of 2020.</p>
            <p>Local honey is known for helping treat local allergies. Please inquire if you are interested in SOR Honey!</p>
            <div id="beeHive" className="honeyPic border1" style={{width: '66%'}}>
                <img alt="" className="hiddenImage" src="/assets/images/farming/honey/BeeHive.jpg"></img>
            </div>
        </FluidContainer>
    )
}
