import React from 'react'

export default function ({children}) {
    return (
            <div className="container-fluid">
                <div className="row">
                    <div className="offset-xl-1 col-xl-10">
                        {children}
                    </div>
                </div>    
            </div>
        )
}