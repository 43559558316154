import React from 'react';

import './naturalHabitat.css'

export default function() {
    return(
        <div className="row">
            <div className="col-xl-9">
                <h1 className="text-center">Natural Habitat</h1>
                <hr />
                <p style={{textAlign: 'justify'}}>We preserve 150+ acres of raw, natural habitat for native wildlife: deer, turkey, beaver, ducks, redtail hawks, gophers, quail, raccoon, possums, skunks, coyotes, wild boars, bobcats, fox, bald and golden eagles, moles, falcons, bullfrogs, wild hogs, leopard frogs, tree frogs, and more, where they live and feed on many things, including acorns, pecans, clover, fish, rye grass, and other native grasses. We also have swarm boxes, natural water sources, and wild flower food sources for our bees to use to expand their hives. Our 21 acres of native Pecan trees are maintained and harvested as naturally as possible.</p>
            </div>
            <div className="col-xl-3">
                <div id="habitat" className="border1">
                    <img id="habitatPic" className="hiddenImage" alt="" src="/assets/images/giving-back/land-management/AnimalTracks.jpg"></img>
                </div>
            </div>
        </div>
    )
}