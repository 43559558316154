import React from 'react'
import {Link} from 'react-router-dom'
import {FluidContainer} from '../../components'

import './farming.css'

export default function() {
    return (
        <FluidContainer>
            <div 
                className='border1' 
                style={{
                    margin: '0 auto',
                    width: '80%',
                    height: '25vh',
                    backgroundImage: 'url("/assets/images/farming/FarmingTractorCropped.jpg")',
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center"
                }}
            >
            </div>
            <h1 className="text-center">Farming</h1>
            <hr />
            <p className="text-center">Split Oak Ranch produces <Link to="/farming/hay">hay</Link>, <Link to='/farming/pecans'>pecans</Link>, and <Link to='/farming/honey'>honey</Link>.</p>
            <div id="farmingPics" className="row">
                <div className="offset-xl-1 col-xl-4">
                    <div id="hayDogs" className="border1 farmingPic" style={{width: '100%', height: '100%'}}>
                        <img alt="" className="hiddenImage" src="/assets/images/farming/HayDogs.jpg"></img>
                    </div>
                </div>
                <div className="offset-xl-2 col-xl-4">
                    <div id="hayField" className="border1 farmingPic" style={{width: '100%', height: '100%'}}>
                        <img alt="" className="hiddenImage" src="/assets/images/farming/HayField.jpg"></img>
                    </div>
                </div>
            </div>
        </FluidContainer>
    )
}
