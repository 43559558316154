import React, {Component} from 'react'

export default class ImagePlaceholder extends Component {
    render(){
        let {width, height, style} = this.props;
        return (
            <div
                style={{
                    ...style,
                    width: width || '100%',
                    height: height || '100%',
                    backgroundImage: "url('/assets/images/utils/Placeholder.jpg')",
                    backgroundSize: '100% 100%'
                }}
            ></div>
        )
    }
}
