import React from 'react';
import {FluidContainer} from '../../components'
import {Link} from 'react-router-dom'

import './history.css'

export default function () {
    return (
        <FluidContainer>
            <h1 className="text-center">History</h1>
            <hr />
            <div className="row">
                <div className="col-xl-3 text-center">
                    <img className="border1" id="saddle" src='/assets/images/about-us/Saddle.jpg' alt="Carl Foster's Saddle from the 1940's"></img>
                    <p>Carl Foster's Cheaney &amp; Sons Saddle from the 1940s</p>
                </div>
                <div
                    className="col-xl-9"
                    style={{
                        paddingLeft: 20
                    }}
                >    
                    <p>Split Oak Ranch is located in Marietta, Oklahoma, in Love County which was established in 1907 at statehood. Prior to that, this land was part of the Chickasaw Nation and was still used for what it does best — Farming &amp; Ranching.</p>
                    <p>Located on the banks of the Red River, our natural resources are plentiful with rolling hills, spring fed ponds, many creeks and tributaries providing drainage for the area, and amazing soil and weather for farming &amp; ranching.</p>
                    <p>The ranch was first established in the 1940s by Carl Foster. Carl raised goats, hogs, and cattle, and farmed cotton, corn, and wheat.  He embraced the importance of resource stewardship and the value of agriculture. These values were passed down through the generations of his family and are still the core values of Split Oak Ranch. <Link to="/about-us/full-history">Click here to read the full history!</Link></p>
                    <p>Recreational areas nearby include:</p> 
                    <ul>
                        <li>
                            <a target="_blank" rel="noopener noreferrer" href='https://www.travelok.com/state-parks/4358'>Lake Murray</a>
                        </li>
                        <li>
                            <a target="_blank" rel="noopener noreferrer" href='https://www.laketexoma.com/'>Lake Texoma</a>
                        </li>
                        <li>
                            <a target="_blank" rel="noopener noreferrer" href='https://www.wildlifedepartment.com/wildlife-management-areas/hickory-creek'>Hickory Creek</a>
                        </li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.turnerfallspark.com/">Turner Falls</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.okhistory.org/publications/enc/entry.php?entry=CR016">Cross Timbers</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="http://www.blueostrich.net/">Blue Ostritch Winery</a></li>
                        <li><a href="https://www.archewines.com/" target="_blank" rel="noopener noreferrer">Arché Winery &amp; Vineyard</a></li>
                        <li><a href="https://www.4rwines.com/" target="_blank" rel="noopener noreferrer">4R Ranch, Vineyards, and Winery</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.wildlifedepartment.com/wildlife-management-areas/love-valley">Love Valley</a></li>
                        <li style={{listStyle: 'none'}}>and for those who prefer neon lights,</li>
                        <li>the <a target="_blank" rel="noopener noreferrer" href='https://www.winstar.com/'>Winstar Casino (The World's Largest Casino™)</a> is just 10 minutes away!</li>
                    </ul> 
                </div>
            </div>
        </FluidContainer>
    )
}