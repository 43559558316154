import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import './navbar.css'

export default class Navbar extends Component {

  state = {
    fixedTop: null
  }

  constructor(props){
    super(props);
    this.aboutUsRef = React.createRef();
    this.farmingRef = React.createRef();
    this.equestrianRef = React.createRef();
    this.specialEventsRef = React.createRef();
    this.givingBackRef = React.createRef();
    this.dropdownDelay = {};
    this.state.fixedTop = window.innerWidth > 1200;
  }

  componentDidMount(){
    window.addEventListener('resize', () => {
      if(this.state.fixedTop && window.innerWidth <= 1200) {
        this.setState({fixedTop: false})
      } else if(!this.state.fixedTop && window.innerWidth > 1200) {
        this.setState({fixedTop: true})
      }
    })
  }
  
  openDropdown({current}) {
    if(this.dropdownDelay[current.id] !== null) {
      clearTimeout(this.dropdownDelay[current.id]);
      this.dropdownDelay[current.id] = null;
    }

    if(current.className.indexOf('active') === -1) {
      current.className += " active";
    }
  }

  closeDropdown({current}) {
    this.dropdownDelay[current.id] = setTimeout(() => {
      if(current.className.indexOf('active') !== -1) {
        current.className = current.className.replace(' active', '');
      }
    }, 50);
  }

  render() {
    let {fixedTop} = this.state
    return (
      <div className="nav-container">
        <nav className={`navbar navbar-expand-xl ${fixedTop ? 'fixed-top' : ''}`}>
          <button
            id="bars"
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarToggler"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <h1><i className="fa fa-bars"></i></h1>
          </button>
          <Link to="/" id="mobileLogoContainer" className="nav navbar-brand">
            <div className="mobileBrandContainer">
              <div id="mobileLogo"></div>
              <h1>Split Oak Ranch</h1>
            </div>
          </Link>
          <div className="collapse navbar-collapse" id="navbarToggler">
            <ul id="navbar-left" className="nav mobile-menu mr-auto justify-content-around">
              <li
                id={'about-us'}
                ref={this.aboutUsRef}
                onMouseEnter={() => {this.openDropdown(this.aboutUsRef)}}
                onMouseLeave={() => {this.closeDropdown(this.aboutUsRef)}}
                className="nav-item dropdown"
              >
                <span className="nav-link dropdown-link no-scroll">About Us</span>
                <div className="dropdown-menu animated slide-in" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to="/about-us/our-team">Meet our Team</Link>
                  <Link className="dropdown-item" to="/about-us/history">History</Link>
                  <Link className="dropdown-item" to="/about-us/contact-us">How to Contact Us</Link>
                </div>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/cattle">Cattle</Link>
              </li>
              <li
                id={'farming'}
                ref={this.farmingRef}
                onMouseEnter={() => {this.openDropdown(this.farmingRef)}}
                onMouseLeave={() => {this.closeDropdown(this.farmingRef)}}
                className="nav-item dropdown"
              >
                <Link className="nav-link dropdown-link" to={location => (window.innerWidth > 1200 ? {...location, pathname: "/farming"} : null)}>Farming</Link>
                <div className="dropdown-menu animated slide-in" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item mobile-visible" to="/farming">Farming</Link>
                  <div className="dropdown-divider mobile-visible"></div>
                  <Link className="dropdown-item" to="/farming/hay">Hay</Link>
                  <Link className="dropdown-item" to="/farming/pecans">Pecans</Link>
                  <Link className="dropdown-item" to="/farming/honey">Honey</Link>
                </div>
              </li>
              <li
                id={'equestrian'}
                ref={this.equestrianRef}
                onMouseEnter={() => {this.openDropdown(this.equestrianRef)}}
                onMouseLeave={() => {this.closeDropdown(this.equestrianRef)}}
                className="nav-item dropdown"
              >
                <Link className="nav-link dropdown-link" to={location => (window.innerWidth > 1200 ? {...location, pathname: "/equestrian"} : null)}>Equestrian</Link>
                <div className="dropdown-menu animated slide-in" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item mobile-visible" to="/equestrian">Equestrian</Link>
                  <div className="dropdown-divider mobile-visible"></div>
                  <Link className="dropdown-item" to="/equestrian/horses">Horses</Link>
                  <Link className="dropdown-item" to="/equestrian/riding-training-facility">Riding / Training Facility</Link>
                </div>
              </li>
            </ul>

            <Link to="/" className="nav navbar-brand">
              <div className="brandContainer">
                <div id="logo"></div>
                <h1>Split Oak Ranch</h1>
              </div>
            </Link>
            
            <ul id="navbar-right" className="nav mobile-menu ml-auto justify-content-around">
              <li
                id={"b+b-and-special-events"}
                ref={this.specialEventsRef}
                onMouseEnter={() => {this.openDropdown(this.specialEventsRef)}}
                onMouseLeave={() => {this.closeDropdown(this.specialEventsRef)}}
                className="nav-item dropdown"
              >
                <Link className="nav-link dropdown-link" to={location => (window.innerWidth > 1200 ? {...location, pathname: "/b+b-and-special-events"} : null)}>B&amp;B + Special Events</Link>
                <div className="dropdown-menu animated slide-in" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to="/b+b-and-special-events">Horse Bed &amp; Breakfast</Link>
                  <Link className="dropdown-item" to="/b+b-and-special-events/special-events">Special Events</Link>
                </div>
              </li>
              <li
                id={'giving-back'}
                ref={this.givingBackRef}
                onMouseEnter={() => {this.openDropdown(this.givingBackRef)}}
                onMouseLeave={() => {this.closeDropdown(this.givingBackRef)}}
                className="nav-item dropdown"
              >
                <span className="nav-link dropdown-link no-scroll">Giving Back &amp; Stewardship</span>
                <div className="dropdown-menu animated slide-in" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to="/giving-back/philosophy">Philosophy</Link>
                  <Link className="dropdown-item" to="/giving-back/land-management">Land Management</Link>
                  <Link className="dropdown-item" to="/giving-back/supporting-military">Supporting Our Military</Link>
                  <Link className="dropdown-item" to="/giving-back/community-service">Community Service</Link>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    )
  }
}