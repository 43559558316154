import React from 'react'
import {FluidContainer} from '../../components'

export default function() {
    return (
        <FluidContainer>
            <h1 className="text-center">Split Oak Ranch History &amp; Fun Facts</h1>
            <hr />
            <ul>
                <li>
                    In the 1920’s there were several concrete structures down by the creek on the west side and it was used as county wide “dipping vats” for cattle, sheep and hogs. 
                </li>
                <li>
                    There is a hand dug stone lined well on the east side by McGehee Road.&ensp;Mr. and Mrs. Foster rented a house there, and when the land became for sale they bought it.
                </li>
                <li>
                    In the 1940’s after the dust bowl, the US Government paid to put terracing in all the fields and pastures to help prevent erosion.&ensp;These terraces are still in place and are an important part of our drainage system.&ensp; 
                </li>
                <li>
                    The far southeast side of the property up on the hill used to be a field where Mr. Foster grew corn.&ensp;
                </li>
                <li>
                    The road that goes up to this field was built by an oil company, and in the 1960s they unsuccessfully drilled for oil up on the hill.&ensp;The pad they left behind was a mess!  There was state funding to fix old well sites, so we engaged them to regrade and clean up this abandoned drilling site.
                </li>
                <li>
                    The original house where Mr. and Mrs. Foster raised their three children was located exactly where the current house’s garage is now.&ensp;That house was an old Craftsman house that was originally build in the area where Lake Texoma now is.&ensp;Mr. and Mrs. Foster had it moved as the lake was being built.
                </li>
                <li>In 2005, the house was moved into Marietta and is now located behind Pugh Roofing.&ensp;Carl’s granddaughter Linda Russell and her husband Bill Russell had taken over living at and operating the farm, and they helped pay to move the historic house and gave it to our mail carrier who renovated it for her children.
                </li>
                <li>
                    The old shop behind the split oak tree was Carl’s workshop.&ensp;He had his CB in there and loved to do metal working.&ensp;We still find old tools and pieces of metal that wash up around that shed and you probably still see the CB antenna on the south side of the building.
                </li>
                <li>
                    There is an old cemetery just north of the end of the old runway called Arnoldville.&ensp;Mrs. Foster’s father is buried there.&ensp;There is a hand carved rock with his name on it, Acre.&ensp;This is a really cool place to explore as there a lot of tombstones.
                </li>
                <li>
                    There is an oak tree in front of Carl’s old shop (which is now the Smoke House with an outdoor kitchen and large BBQ grill).&ensp;The oak tree was struck by lightning and completely split in half.&ensp; The tree mended itself and continued to grow into a beautiful and strong oak tree.&ensp;This is the inspiration for the ranch: Split Oak Ranch, Where Roots Run Deep
                </li>

            </ul>
        </FluidContainer>
    );
}