import React, {Component} from 'react'

export default class FlexContainer extends Component {
    render(){
        let {style, children, flexDirection, childrenStyles} = this.props;

        let now = new Date().getMilliseconds();
      
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: flexDirection || 'row',
                    justifyContent: 'space-around',       
                    alignItems: 'stretch',
                    ...style
                }}
            >
                {children.map((child, i) => (
                    <div
                        style={{
                            flex: child.props.flex || '1 0 auto',
                            ...childrenStyles
                        }}
                        key={`flexChild-${now}-${i}`}
                    >
                        {child}
                    </div>
                ))}
            </div>
        )
    }
}