import React from 'react'
import { Ponds, RotationalCrops, LandRestoration, NaturalHabitat, ProtectSoil } from '.'
import FlexContainer from '../../../components/FlexContainer'
import FluidContainer from '../../../components/FluidContainer'

import './landManagement.css'
import { LandCarousel } from '../../../components'

export default function() {
    return (
        <FluidContainer>
            <h1 className="text-center">Land Management</h1>
            <hr />
            <FlexContainer
                style={{
                    width: '80vw',
                    margin: '0 auto'
                }}
                flexDirection='column'
                childrenStyles={{
                    padding: 10
                }}
            >
                <Ponds />
                <hr />
                <LandRestoration />
                <hr />
                <RotationalCrops />
                <hr />
                <ProtectSoil />
                <hr />
                <NaturalHabitat />
                <hr />
            </FlexContainer>
            <LandCarousel />
        </FluidContainer>
    )
}
