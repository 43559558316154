import React, {Component} from 'react';
import {HashRouter as Router,Switch,Route} from "react-router-dom";

import './App.css';
import {Navbar} from './components';
import { Home } from './pages';
import Cattle from './pages/cattle'
import HorseBNB, { SpecialEvents, PatriotRoundup } from './pages/bnb-and-special-events';
import Farming, { Hay, Pecans, Honey, Hemp } from './pages/farming';
import Equestrian, { Horses, RidingTrainingFacility } from './pages/equestrian';
import AboutUs, { OurTeam, History, FullHistory, ContactUs } from './pages/about-us';
import GivingBack, { LandManagement, Philosophy, SupportingMilitary, CommunityService } from './pages/giving-back'

export default class App extends Component {

  state = {
    padTop: null
  }

  constructor(props){
    super(props);
    this.state.padTop = window.innerWidth > 1200;
  }
  
  componentDidMount() {
    window.addEventListener('resize', () => {
      if(this.state.padTop && window.innerWidth <= 1200){
        this.setState({padTop: false})
      } else if(!this.state.padTop && window.innerWidth > 1200) {
        this.setState({padTop: true})
      }
    })
  }

  render() {
    let {padTop} = this.state;
    return ( 
      <Router basename='/'>
        <Navbar />
        <div
          style={{
            paddingTop: padTop ? 86 : 0
          }}
        >
          <div style={{padding: 10}}>
            <Switch>
              <Route path="/cattle">
                <Cattle />
              </Route>
              <Route path="/about-us/history">
                <History/>
              </Route>
              <Route path="/about-us/our-team">
                <OurTeam/>
              </Route>
              <Route path="/about-us/full-history">
                <FullHistory/>
              </Route>
              <Route path="/about-us/contact-us">
                <ContactUs />
              </Route>
              <Route path="/about-us">
                <AboutUs />
              </Route>
              <Route path="/equestrian/horses">
                <Horses />
              </Route>
              <Route path="/equestrian/riding-training-facility">
                <RidingTrainingFacility />
              </Route>
              <Route path="/equestrian">
                <Equestrian />
              </Route>
              <Route path="/farming/hay">
                <Hay />
              </Route>
              <Route path="/farming/pecans">
                <Pecans />
              </Route>
              <Route path="/farming/honey">
                <Honey />
              </Route>
              <Route path="/farming/hemp">
                <Hemp />
              </Route>
              <Route path="/farming">
                <Farming />
              </Route>
              <Route path="/b+b-and-special-events/special-events">
                <SpecialEvents />
              </Route>
              <Route path="/special-events/patriot-roundup">
                <PatriotRoundup />
              </Route>
              <Route path="/b+b-and-special-events">
                <HorseBNB />
              </Route>
              <Route path="/giving-back/land-management">
                <LandManagement />
              </Route>
              <Route path="/giving-back/supporting-military">
                <SupportingMilitary />
              </Route>
              <Route path="/giving-back/community-service">
                <CommunityService />
              </Route>
              <Route path="/giving-back/philosophy">
                <Philosophy />
              </Route>
              <Route path="/giving-back">
                <GivingBack />
              </Route>
              {/* NOTHING GOES BELOW THIS LINE!!! */}
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
    )
  }
}
