import React, { Component } from 'react';
import {FluidContainer} from '../../components';
import {Carousel} from 'react-bootstrap';

import './cattle.css';

export default class Cattle extends Component {
    render(){  
        return (
            <FluidContainer>
                <h1 className="text-center">Cattle</h1>
                <hr />
                <p>Split Oak Ranch is a beef cattle ''Cow - Calf operation''. We breed Black Angus and Charolais cattle for Spring and Fall calving. Calves are available for purchase after weaning and vaccinations.</p>
                <p>We believe that all of God's creatures are precious gifts! We take great pride in our cattle stewardship and provide excellent care for our bulls, cows, and calves. Our cows graze year round on well-maintained Bermuda grass pastures, and in Winter they are fed supplemental hay harvested from their home here at the Split Oak Ranch.</p>
                <div className="card bg-dark">
                    <Carousel id="cattleCarousel" pauseOnHover={false} indicators={false}>
                        <Carousel.Item id='Mongo'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/cattle/Mongo.jpg" alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='BlackAngusCalf'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/cattle/BlackAngusCalf.png" alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='BlackAngusCowCalf'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/cattle/BlackAngusCowCalf.jpg" alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='CharolaisCowCalf'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/cattle/CharolaisCowCalf.jpg" alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='FeedingTime'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/cattle/FeedingTime.jpg" alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='Jingles'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/cattle/Jingles.jpg" alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='LoseYourWayInTheHay'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/cattle/LoseYourWayInTheHay.jpg" alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='Stretching'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/cattle/Stretching.jpg" alt=""/>
                        </Carousel.Item>
                        <Carousel.Item id='SunsetCows'>
                            <img className="d-block mx-auto hiddenImage" src="/assets/images/cattle/SunsetCows.jpg" alt=""/>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </FluidContainer>
        )
    }
}
