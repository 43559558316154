import React from 'react'
import { FluidContainer } from '../../components'

import './pecans.css'

export default function() {
    return (
        <FluidContainer>
            <h1 className="text-center">Pecans</h1>
            <hr />
            <p>Pecans are a secondary farm product for us. Split Oak Ranch has 21 acres of native Pecan trees including some paper-shell grafted Pecans.</p>
            <br />
            <div id="pecanTrees" className="pecanPic border1">
                <img alt="" className="hiddenImage" src="/assets/images/farming/pecans/PecanTrees.jpg"></img>
            </div>
        </FluidContainer>
    )
}
