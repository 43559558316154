import React, { Component } from 'react'
import { Carousel } from 'react-bootstrap'

const imageList = [
    "CowsOnPondHill",
    "Pasture5",
    "Creek3",
    "HayRows",
    "Pasturewtree",
    "PecanBottom1",
    "Creek5",
    "PecanBottom5",
    "Hayfield2",
    "Wildflowers",
    "Sycamore",
    "Pond2",
    "Rainbow",
    "Woodsroad2",
    "Clover",
    "Woodsroad",
    "CowsAtPond",
    "Crosstimbers",
    "Field1",
    "RollingGreenHills",
    "Timber1",
    "AnimalTracks",
    "Fence2pecans",
    "Pond3",
    "BackPond"
];

export default class LandCarousel extends Component {
    render() {
        const {mainPage} = this.props
        if(mainPage) {
            return (
                <div className="card bg-dark">    
                    <Carousel id="landMgmtCarousel" pauseOnHover={false} indicators={false}>
                        <Carousel.Item id="TheSplitOak">
                            <img
                                alt="The Split Oak"  
                                style={{
                                    backgroundImage: 'url("/assets/images/TheSplitOak.jpg")',
                                    backgroundSize: "contain"
                                }}
                                className="d-block mx-auto hiddenImage"
                                src={"/assets/images/TheSplitOak.jpg"}
                            />
                            <Carousel.Caption>
                                <h1 style={{fontWeight: 800, color: '#FFF', textShadow: '0 0 2px #000'}}>The Split Oak</h1>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item id="TheSplitOak2Cropped">
                            <img
                                alt="The Split Oak (Full Tree)"
                                style={{
                                    backgroundImage: 'url("/assets/images/TheSplitOak2Cropped.jpg")',
                                    backgroundSize: "contain"
                                }}
                                className="d-block mx-auto hiddenImage"
                                src={"/assets/images/TheSplitOak2Cropped.jpg"}
                            />
                        </Carousel.Item>
                        {imageList.map(filename => (
                            <Carousel.Item key={filename} id={filename}>
                                <img
                                    alt={filename}  
                                    style={{
                                        backgroundImage: `url("/assets/images/giving-back/land-management/${filename}.jpg")`,
                                        backgroundSize: "contain"
                                    }}
                                    className="d-block mx-auto hiddenImage"
                                    src={`/assets/images/giving-back/land-management/${filename}.jpg`}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            )
        } else {
            return (
                <div className="card bg-dark">    
                    <Carousel id="landMgmtCarousel" pauseOnHover={false} indicators={false}>
                        {imageList.map(filename => (
                            <Carousel.Item key={filename} id={filename}>
                                <img
                                    alt={filename}
                                    style={{
                                        backgroundImage: `url("/assets/images/giving-back/land-management/${filename}.jpg")`,
                                        backgroundSize: "contain"
                                    }}
                                    className="d-block mx-auto hiddenImage"
                                    src={`/assets/images/giving-back/land-management/${filename}.jpg`}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            )
        }
    }
}

// <Carousel.Caption>
//     <h1 style={{fontWeight: 800, color: '#f00', textShadow: '0 0 2px #000'}}>{filename}</h1>
// </Carousel.Caption>